import React from 'react'
import {SimpleGrid, Heading, Box, Container, Text, Grid, GridItem,Center } from '@chakra-ui/react'
import { FlyingEnvelope, FlyingEnvelopeLeft } from '../components/animations/envelope'
import ContactForm, {RealContact} from '../components/contact/Contact'
import { mypx, xgap } from '../constants/spacing'

const ContactSection = () => {
    return (
        <SimpleGrid id="contact" columns={1} gap={xgap} px={mypx} py={["25vh",null,null,null,"25vw"]} >


    <Grid alignContent="center" justifyContent="space-evenly" templateRows={["(3, 1fr)",null,null,null,"(1, 1fr)"]} templateColumns={["(1,1fr)",null,null,null,"(3, .25fr .50fr .25fr)"]}>
        <GridItem colStart={[1, null,null,null,1]} colEnd={[1, null,null,null,1]} rowStart={[1, null,null,null,1]} rowEnd={[1, null,null,null,1]}>
        <Center>
            <FlyingEnvelopeLeft />
          </Center>
        </GridItem >
        <GridItem colStart={[1, null,null,null,2]} colEnd={[1, null,null,null,2]} rowStart={[2, null,null,null,1]} rowEnd={[2, null,null,null,1]}>
        <Box>
            <Heading
              fontFamily='Archivo'
              fontSize={["5xl", "7xl"]}
              textAlign='center'
              fontWeight='extrabold'>
              Say Hi!
            </Heading>
            <Container>
              <Text
                textAlign='center'
                fontSize={["16px",null,"24px",null,"20px"]}
                fontFamily='Acumin-Pro'
                fontWeight='100'
                pb={5}
                fontStyle="normal">
                Check on availability, latest news, or just talk shop.{" "}
              </Text>
            </Container>
            <Box>
              <RealContact/>
            </Box>
          </Box>
        </GridItem>
        <GridItem colStart={[1, null,null,null,3]} colEnd={[1, null,null,null,3]} rowStart={[3, null,null,null,1]} rowEnd={[3, null,null,null,1]}> 
        <Center>
            <FlyingEnvelope />
          </Center>
        </GridItem>
 </Grid>
          
        </SimpleGrid>
    )
}

export default ContactSection
