import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import {Box} from '@chakra-ui/react'
import { Link } from "gatsby";
import SilentPlayer from "../video/SilentPlayer";

const preventClick = (e) => e.preventDefault()

const HomeCard = ({image, alt="", title="The Copper Club", title1="", title2="", title3="", title4="",category1, category2, category3, category4, category5, href="/", gif, video, categories,pointerEvents}) => {

  const preventContextMenu = (e) => {
      e.preventDefault()
      return false;
  }

  title1 = title.split(' ',0)
  console.log(`This is ${title1}`)
  return (
      
    <Box onContextMenu={preventClick} pointerEvents={pointerEvents} className='card-wrapper'  overflow='hidden'>
        <Link  className="homecard-link-1" to={href}>
            
    {image ? <GatsbyImage style={{overflow:'hidden', }} className="homecard-image" image={image} alt={alt}/> : null }
    {gif ? <img style={{width:'100%'}} src={gif} alt={alt}/> : null }
    {video ? <SilentPlayer url={video} alt={alt} /> : null }
  
      </Link>
      
      <Box  className='card-body' py={2,3,null,null,4} px={[0,2,4,null,5]}>
          
        <Box
          textTransform="uppercase"
          className='card-title'
          mt='-1'
          as='h4'
          fontFamily='Archivo'
          fontWeight="bold"
          fontSize={["xl","3xl","4xl",null,"5xl"]}
          lineHeight='tight'
          isTruncated>
        <Link style={{pading:0, margin:0}} href={href}>  {title}</Link>
        </Box>
        
        <Box
          className='card-categories'
          
          mt='-1'
          as='h4'
          color="#6A6C6E"
          fontFamily='Franklin-Gothic-Book'
          fontSize={["sm",null,null,"lg"]}
          lineHeight='short'
          isTruncated>
            {categories}
          {/* {category1}
        {category2 ? `, ${category2}`: null}{category3 ? `, ${category3}`: null}{category4 ? `, ${category4}`: null}{category5 ? `, ${category5}`: null}    */}
        </Box>
      </Box>
      
    </Box>
  );
};

export default HomeCard;

export const HomeCardStatic = ({image, alt="", title, category1, category2,category3,category4,category5, href="/", gif, video,children, categories}) => {

  
  return (
      
    <Box onContextMenu={preventClick} className='card-wrapper'  overflow='hidden'>
        <Link  href={href}>
            
{children}
      </Link>
     
      <Box onContextMenu={preventClick} className='card-body' py={2,3,null,null,4} px={[0,2,4,null,5]}>
          
        <Box
         onContextMenu={(e) => e.preventDefault()}
         textTransform="uppercase"
         className='card-title'
         mt='-1'
         as='h4'
         fontFamily='Archivo'
         fontWeight="bold"
         fontSize={["xl","3xl","4xl",null,"5xl"]}
         lineHeight='tight'
         isTruncated>
        <Link href={href}>  {title}</Link>
        </Box>
        
        <Box
           className='card-categories'
          
           mt='-1'
           as='h4'
           color="#6A6C6E"
           fontFamily='Franklin-Gothic-Book'
           fontSize={["sm",null,null,"lg"]}
           lineHeight='short'
           isTruncated>
             {categories}
                    {/* {category1},{" "}{category2},{" "} {category3},{" "}{category4} */}
        {/* {category2 ? `, ${category2}`: null}   {category3 ? `, ${category3}`: null}   {category4 ? `, ${category4}`: null}   {category5 ? `, ${category5}`: null}    */}
        </Box>
      </Box>
      
    </Box>
  );
};