import React from "react";
import { FaRegEnvelope } from "react-icons/fa";
import { Box, Flex, Container, IconButton } from "@chakra-ui/react";
import { animated, useSpring, config } from "react-spring";

export const FlyingEnvelope = ({fontSize="124px"}) => {
    const AniEnv = animated(FaRegEnvelope)
    const spring = useSpring({
        from: {x:0, y:0, rotateZ: 0},
        to: {x:500, y:-300, rotateZ:180},
        loop: {reverse: true},
        config: config.slow
    })
    return(
        <AniEnv  style={{...spring, fontSize:`${fontSize}`}} />
    )
}

export const FlyingEnvelopeLeft = ({fontSize="124px"}) => {
    const AniEnv = animated(FaRegEnvelope)
    const spring = useSpring({
        from: {x:0, y:0, rotateZ: 0},
        to: {x:-500, y:-300, rotateZ:-180},
        loop: {reverse: true},
        config: config.slow
    })
    return(
        <AniEnv  style={{...spring, fontSize:`${fontSize}`, zIndex:-1}} />
    )
}

const Envelope = () => {

    const AniButton = animated(IconButton)

    const spring = useSpring({
        from: {x:0},
        to: {x:100},
        loop: {reverse: true},
        config: config.wobbly
    })


    return (
    <div>
      <Flex align='center' border='1px solid black'>
        <Box>
          <AniButton borderRight="1px solid black"  icon={<FlyingEnvelope />} />
        </Box>
        <Box>
          <Container>Mail@HollandHames.com</Container>
        </Box>
      </Flex>
    </div>
  );
};

export default Envelope;

