import React from "react";
import {

  SimpleGrid,

} from "@chakra-ui/react";

import coppergif from '../images/copper/image5.gif'
import sun from '../video/sun2.mp4'
import {ParallaxProvider} from 'react-scroll-parallax'
import { xgap, mypx, col2 } from "../constants/spacing";
import { graphql } from "gatsby";

import { getImage, GatsbyImage, StaticImage } from "gatsby-plugin-image";
import Hvid from "../video/hstudy_v3.mp4";

import HomeCard, { HomeCardStatic } from "../components/cards/HomeCard";
import SilentPlayer from "../components/video/SilentPlayer";
import HomeHeader from "../components/Header/HomeHeader";
import AboutSection from "../sections/AboutSection";
import ContactSection from "../sections/ContactSection";
import MobileVideo from '../images/home/home-mobile.mp4'
import DesktopVideo from '../images/home/home-desktop.mp4'
import SEO from "../components/SEO";

const index = ({ data }) => {
  //   const edges = data.allWpProject.edges
  //   console.log(edges)

  // const wilson = edges[0]
  // console.log(wilson)

  // const wil = wilson.node.hollandProjects
  console.log(data);
  // const wilsonImg = getImage(wil.image1.localFile)

  
  const one = data.allWpProject.edges[0].node.hollandProjects;
  const two = data.allWpProject.edges[1].node.hollandProjects;
  const three = data.allWpProject.edges[2].node.hollandProjects;
  const four = data.allWpProject.edges[3].node.hollandProjects;
  const five = data.allWpProject.edges[4].node.hollandProjects;
  const six = data.allWpProject.edges[5].node.hollandProjects;
  const seven = data.allWpProject.edges[6].node.hollandProjects;
  const eight = data.allWpProject.edges[7].node.hollandProjects;
  const nine = data.allWpProject.edges[8].node.hollandProjects;
  const ten = data.allWpProject.edges[9].node.hollandProjects;
  const image1 = getImage(one.homeImage.localFile);
  const image2 = getImage(two.homeImage.localFile);
  const image3 = getImage(three.homeImage.localFile);
  const image4 = getImage(four.homeImage.localFile);
  const image5 = getImage(five.homeImage.localFile);
  // const image6 = getImage(six.homeImage.localFile);
  const image7 = getImage(seven.homeImage.localFile);
  const image8 = getImage(eight.homeImage.localFile);
  const image9 = getImage(nine.homeImage.localFile);
  const image10 = getImage(ten.homeImage.localFile);
  return (
    <>
   <SEO title="Holland Hames | Design Wizard" description="Holland Hames is a multidisciplinary designer specializing in brand, print, and digital design with a passion for typography. My work expands across freelance, agency and in house to study all types of clients. If you'd like to discuss working together, send me an email!" url="https://hollandhames.com" image='../images/thank-you.png' />
    <div>
      <HomeHeader
        mobileVid={MobileVideo}
        desktopVid={DesktopVideo}
      />
      <SimpleGrid px={mypx} py={xgap} gap={xgap} columns={1}>
        <SimpleGrid
          justify='center'
          alignItems='start'
          gap={xgap}
          columns={col2}>
          <HomeCard
            href='/project/wilson'
            image={image1}
            title={one.projecttitle}
            category1={one.projectcategories[0]}
            category2={one.projectcategories[1]}
            category3={one.projectcategories[2]}
            category4={one.projectcategories[3]}
            categories={one.projectcategories.join(", ")}
          />
          {/* <HomeCard image={wilsonImg} category1={wil.projectcategories.[0]} category2={wil.projectcategories.[1]} title={wil.projecttitle} alt={wil.image1.altText}/> */}
          <HomeCard
            href='/project/vpa'
            image={image2}
            title={two.projecttitle}
            category1={two.projectcategories[0]}
            category2={two.projectcategories[1]}
            categories={two.projectcategories.join(", ")}
          />
        </SimpleGrid>
        <SimpleGrid
          gap={xgap}
          columns={1}
          alignItems='center'
          justifyItems='center'>
          <HomeCard
            href='/project/hellofresh-packaging'
            image={image3}
            title={three.projecttitle}
            category1={three.projectcategories[0]}
            category2={three.projectcategories[1]}
            categories={three.projectcategories.join(", ")}
          />
        </SimpleGrid>
        <SimpleGrid alignItems='flex-end' columns={col2} gap={xgap}>
          <SimpleGrid columns={1} gap={xgap}>
            <HomeCard
              href='/project/hellofresh-marketing'
              image={image4}
              title={four.projecttitle}
              category1={four.projectcategories[0]}
              category2={four.projectcategories[1]}
              categories={four.projectcategories.join(", ")}
            />
          </SimpleGrid>
          <SimpleGrid columns={1} gap={xgap}>
            <SilentPlayer url={Hvid} />
            <HomeCard
              href='/project/nfl'
              image={image5}
              title={five.projecttitle}
              category1={five.projectcategories[0]}
              category2={five.projectcategories[1]}
              categories={five.projectcategories.join(", ")}
            />
          </SimpleGrid>
        </SimpleGrid>
        {/* <Grid gap={xgap}  templateColumns={tc} templateRows={["(3,1fr)",null,null,null,"(5, 1fr)"]}>
          <GridItem colStart={1} colEnd={1} rowStart={1} rowEnd={[1,null,null,5]}>
            <HomeCard image={image4} title={four.projecttitle} category1={four.projectcategories[0]} category2={four.projectcategories[1]} />
          </GridItem>
          <GridItem colStart={[1,null,null,null,2]} colEnd={[1,null,null,null,2]} rowStart={[2,null,null,null,1]} rowEnd={[2,null,null,null,3]}>
            <SilentPlayer url={Hvid} />
          </GridItem>
          <GridItem colStart={[1,null,null,null,2]} colEnd={[1,null,null,null,2]} rowStart={[3,null,null,null,3]} rowEnd={[3,null,null,null,5]}>
            <HomeCard image={image5}title={five.projecttitle} category1={five.projectcategories[0]} category2={five.projectcategories[1]} />
          </GridItem>
        </Grid> */}
        <SimpleGrid columns={col2} gap={xgap}>
          <SimpleGrid columns={1}>
            <HomeCardStatic
              href="/project/copper-club"
              title={six.projecttitle}
              category1={six.projectcategories[0]}
              category2={six.projectcategories[1]}
              categories={six.projectcategories.join(", ")}
            ><img style={{borderRadius: 'none'}}src={coppergif} /></HomeCardStatic>
            <SilentPlayer url={sun} />
          </SimpleGrid>
          <SimpleGrid columns={1}>
            <HomeCard
              // pointerEvents="none"
              image={image7}
              href="/explore"
              title="Explore"
              category1=""
              category2=""
            />
            
          </SimpleGrid>
        </SimpleGrid>
        <SimpleGrid gap={xgap}>
          <HomeCard
            href='/project/le-lapin'
            image={image8}
            title={eight.projecttitle}
            category1={eight.projectcategories[0]}
            category2={eight.projectcategories[1]}
            categories={eight.projectcategories.join(", ")}
          />
        </SimpleGrid>
        <SimpleGrid
          justify='center'
          alignItems='center'
          columns={col2}
          gap={xgap}>
          <HomeCard
            href='/project/parkhouse'
            image={image9}
            title={nine.projecttitle}
            category1={nine.projectcategories[0]}
            category2={nine.projectcategories[1]}
            categories={nine.projectcategories.join(", ")}
          />
          <HomeCard
            href='/project/more-goodies'
            image={image10}
            title={ten.projecttitle}
            category1={ten.projectcategories[0]}
            category2={ten.projectcategories[1]}
            categories={ten.projectcategories.join(", ")}
          />
        </SimpleGrid>
        {/* <SimpleGrid zIndex="5" p={[2,null,null,null,2]} alignItems="center" justifyItems="space-between" bg='#232323' gap={[2,null,null,null,xgap]} columns={col2}>
          <Box overflow='hidden'>
            <Box>
              <SilentPlayer url={ArtVid} />
            </Box>
          </Box>
              <Container pt={8} px={[2,null,null,null,6]} bg="#434343">
              <Container>
                <Heading
                  fontSize={["5xl"]}
                  color='#e8dada'
                  fontFamily='Rigid-Square'
                  fontWeight='bold'>
                  About
                </Heading>
              </Container>
              <Container  size="xs" pb={6}>
                <Text
                  fontFamily='Acumin-Pro-Semi-Condensed'
                  color='#e8dada'
                  fontSize='larger'>
                  {" "}
                  Holland is bad ass and you should give him all your money. His
                  cousin, McKenzie, is the man, too. Let us make your site more
                  bad a$$.
                </Text>
              </Container>
              <Container>
                <Heading
                  fontSize={["5xl"]}
                  color='#e8dada'
                  fontFamily='Rigid-Square'
                  fontWeight='bold'>
                  Services
                </Heading>
              </Container>
              <Container size="sm" pb={6}>
                <SimpleGrid  columns={2}fontFamily='Acumin-Pro-Semi-Condensed'
                  color='#e8dada'
                  fontSize='larger'>
                  <UnorderedList p="0" m="0"  textAlign="left" styleType="none">
                    <ListItem>Art Direction</ListItem>
                    <ListItem>Brand Identity</ListItem>
                    <ListItem>Packaging</ListItem>
                    <ListItem>Campaigns</ListItem>
                    <ListItem>Motion Graphics</ListItem>
                    <ListItem>Graphic Design</ListItem>
                    </UnorderedList>      
                    <UnorderedList p="0" m="0"styleType="none">
                    <ListItem>Illustrations</ListItem>
                    <ListItem>Editorial Designs</ListItem>
                    <ListItem>Digital Design</ListItem>
                    <ListItem>Presentation Design</ListItem>
                    <ListItem>Typography</ListItem>
                    <ListItem>Web Design</ListItem>
                    </UnorderedList>      
                </SimpleGrid>
              </Container>
              </Container> */}
              </SimpleGrid>
              
  <AboutSection />
 <ContactSection />
        {/* <Grid templateColumns={["(1, 1fr)",null,null,null,"(4, 1fr)"]}>
          <GridItem colStart={1} colEnd={[1,null,null,null,3]}>
          <Box overflow="hidden">
            <Box p={xgap}>
            <SilentPlayer url={ArtVid} /></Box>
          </Box>
          </GridItem>
          <GridItem colStart={[1,null,null,null,3]} colEnd={[1,null,null,null,4]}>
          <Box bg="red" m={xgap}>
            <Box p={xgap}>
            <Heading fontFamily="Rigid-Square">About</Heading>
            </Box>
          </Box>
          </GridItem>
        </Grid> */}
        {/* <Grid  justify="center" alignItems="center" gap={xgap} templateColumns={tc} templateRows={["(3, 1fr)",null,null,null,"(4, 1fr)"]}>
          <GridItem colStart={[1,null,null,null,2]} colEnd={[1,null,null,null,2]} rowStart={[2,null,null,null,1]} rowEnd={[1,null,null,null,4]}>
            <HomeCardStatic title="Get a Quote" category1="" category2="" >
            <StaticImage src="https://via.placeholder.com/1500x2000" />
            </HomeCardStatic>
          </GridItem>
          <GridItem colStart={1} colEnd={1} rowStart={1} rowEnd={[1,null,null,null,2]}>
            <HomeCardStatic title={six.projecttitle} category1={six.projectcategories[0]} category2={six.projectcategories[1]}>
              <img src={six.homeImage.localFile.publicURL} />
            </HomeCardStatic>
          </GridItem>
          <GridItem colStart={1} colEnd={1} rowStart={2} rowEnd={[3,null,null,null,4]}>
          <SilentPlayer url={Hvid} />
          </GridItem>
        </Grid> */}
    
    </div>
  
    </>
  );
};


export default index;

export const query = graphql`
  {
    allWpProject(sort: {fields: hollandProjects___homepagePosition, order: ASC}) {
      edges {
        node {
          title
          hollandProjects {
            projecttitle
            projectcategories
            homepagePosition
            homeImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
          }
        }
      }
    }
  }
`;

// export const query = graphql`
//   {
//     allWpProject(sort: { fields: hollandProjects___homepagePosition }) {
//       edges {
//         node {
//           hollandProjects {
//             projecttitle
//             projectcategories
//             homeImage {
//               localFile {
//                 publicURL
//                 extension
//                 childImageSharp {
//                   gatsbyImageData
//                 }
//               }
//             }
//             headerVideoDesktop {
//               localFile {
//                 publicURL
//               }
//             }
//             headerVideoMobile {
//               localFile {
//                 publicURL
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `;

// export const homequery = graphql`
//   {
//     allWpProject {
//       edges {
//         node {
//           title
//           hollandProjects {
//             projecttitle
//             projectcategories
//             image1 {
//               altText
//               localFile {
//                 childImageSharp {
//                   gatsbyImageData(placeholder: TRACED_SVG)
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }

// `
