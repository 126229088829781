import React from 'react'
import ReactPlayer from 'react-player'
import {Box} from '@chakra-ui/react'
import Poster from '../../images/poster1.jpg'
const HomeHeader = ({
    desktopVid,
    mobileVid,
    h="100w",
    w="100vw"

    
}) => {
const preventClick = (e) => e.preventDefault()
  

    return (
        <>
         <Box  onContextMenu={preventClick}  h={h} w={w} overflow='hidden' display={["none", null, "block"]} pb={[10,null,null,null,20]}>
        <ReactPlayer
          url={desktopVid}
          autoPlay={true}
          playsinline={true}
          autoplay={true}
          muted={true}
          loop={true}
          playing={true}
          width='100vw'
          height='100w'
          controls={false}
          
        />
      </Box>

      <Box h={h} w={w} display={["block", null, "none"]} pb={[20,null,null,null,20]}>
        <ReactPlayer
          url={mobileVid}
          autoPlay={true}
          playsinline={true}
          autoplay={true}
          muted={true}
          loop={true}
          playing={true}
          width='100vw'
          height='100w'
          controls={false}
        />
      </Box>
        </>
    )
}

export default HomeHeader
