import React from 'react'
import { xgap, mypx, tc, col2, col3 } from '../constants/spacing'
import {Box, SimpleGrid, Center, Flex, Heading, Container, Text, UnorderedList, ListItem,} from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import { Parallax, ParallaxProvider} from 'react-scroll-parallax'
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
  } from "react-device-detect";

import hollandgif from '../images/holland-gif.gif'

const AboutSection = () => {
    return (
       
        <SimpleGrid  alignItems="center" justifyItems="center"  autoFit bg='#232323' columns={col2}>
 
    
 <ParallaxProvider scrollAxis="vertical">  
 
            <Center w="100%" h="100%" px={[0,null,null,null,'5%']} py={[0,null,null,null,'20%']}> 
               
               
            {/* <Parallax y={['50','-50']}> */}
            <img src={hollandgif}  />
          {/* <StaticImage aspectRatio={1}  src='../images/home/holland-new.jpg' /> */}
          {/* </Parallax> */}
          </Center>
     
       
          
            
          </ParallaxProvider> 
       
      
        {/* <Box h="100%" w="100%" px={25} py={25}> */}
       <Center h="100%" w="100%"          py={[0,null,null,null,'20%']}
            px={[0,null,null,null,'5%']}>
          <Flex
            py={[10,null,null,null,10]}
            px={[0,null,null,null,10]}
            align='center'
            h="100%"
            w="100%"
            m='0 auto'
            bg='#424242'>
              <Box m="0 auto">
            <Container>
              <Heading
                fontSize={["5xl"]}
                color='#e8dada'
                fontFamily='Archivo'
                
                fontWeight="800"
                fontStyle="normal">
                About
              </Heading>
            </Container>
            <Container size='xs' pb={6}>
              <Text
                fontFamily='Acumin-Pro'
                fontWeight="light"
                color='#e8dada'
                fontSize={['md','larger']}>
                {" "}
                Holland Hames is a multidisciplinary designer specializing in brand, print, and digital design with a passion for typography. My work expands across freelance, agency and in house to study all types of clients. If you'd like to discuss working together, send me an email!
              </Text>
            </Container>
            <Container>
              <Heading
                fontSize={["5xl"]}
                color='#e8dada'
                fontFamily='Archivo'
                fontWeight='800'>
                Services
              </Heading>
            </Container>
            <Container size='sm' pb={6}>
              <SimpleGrid
                columns={2}
                fontFamily='Acumin-Pro'
                fontWeight="200"
                fontStyle="light"
                color='#e8dada'
                fontSize={['sm','md','larger']}>
                <UnorderedList p='0' m='0' textAlign='left' styleType='none'>
                  <ListItem>Art Direction</ListItem>
                  <ListItem>Brand Identity</ListItem>
                  <ListItem>Packaging</ListItem>
                  <ListItem>Campaigns</ListItem>
                  <ListItem>Motion Graphics</ListItem>
                  <ListItem>Graphic Design</ListItem>
                </UnorderedList>
                <UnorderedList p='0' m='0' styleType='none'>
                  <ListItem>Illustrations</ListItem>
                  <ListItem>Editorial Designs</ListItem>
                  <ListItem>Digital Design</ListItem>
                  <ListItem>Presentation Design</ListItem>
                  <ListItem>Typography</ListItem>
                  <ListItem>Web Design</ListItem>
                </UnorderedList>
              </SimpleGrid>
            </Container>
            </Box>
          </Flex> 
          </Center>
        {/* </Box> */}
      </SimpleGrid>
     
    )
}

export default AboutSection
